<template>
  <div class="searchNewsBox">
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>新闻搜索</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <div class="snb_top">
        <div class="snbt_searchBox">
          <div class="search">
            <div class="search_input">
              <div class="left_search">
                <el-input v-model="input"></el-input>
              </div>
              <div class="search_icon" @click="search">
                <img
                  title="点击搜索"
                  src="http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/demand/static/2019-11-01/1e061c1e-6b7f-4161-8c95-ad83c90ac6ab.png"
                  alt
                />
              </div>
            </div>
          </div>
          <div class="snbt_hotWordBox" v-html="hotWord"></div>
        </div>
      </div>
      <div class="snb_bottom">
        <el-row>
          <el-col :span="24">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="全部" name="first"></el-tab-pane>
              <el-tab-pane label="海南要闻" name="second"></el-tab-pane>
              <el-tab-pane label="最新政策" name="third"></el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
        <div class="snb_title">
          <el-col :span="12">
            共有
            <span>{{total}}</span>个相关结果
          </el-col>
          <el-col :span="12">
            <el-col :span="6">
              <div class="snbmr_title">
                <div class="snbmrt_font">日期筛选</div>
              </div>
            </el-col>
            <el-col :span="18">
              <el-date-picker
                v-model="dateVal"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeData"
              ></el-date-picker>
            </el-col>
          </el-col>
        </div>
        <div class="snb_main">
          <el-col :span="18">
            <div class="snbm_newsList">
              <div
                class="snbmn_block"
                v-for="(item,index) in newsList"
                :key="index"
                @click="toDetail(item.columnId,item.informationId)"
              >
                <div class="snbmn_row1">{{item.title}}</div>
                <div class="snbmn_row2" v-html="item.detail"></div>
                <div class="snbmn_row3">
                  <span>{{item.sourceName}}</span>
                  {{item.time}}
                </div>
              </div>
            </div>
            <div class="snbm_pageBox">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :current-page.sync="pageNum"
                @current-change="paginationChange"
              ></el-pagination>
            </div>
          </el-col>
          <el-col :span="6">
            <!-- <div class="snbm_right">
              <div class="snbmr_title">
                <div class="snbmrt_font">日期筛选</div>
              </div>
              <el-date-picker
                v-model="dateVal"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>-->
          </el-col>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { DatePicker } from "element-ui";
Vue.use(DatePicker);

export default {
  data() {
    return {
      columnIds: [
        "d1707e23348143fb99537c9bddffcdb3", // 最新要闻
        "3373f13e83b74f12a213a53db20a6657" // 最新政策
      ],
      allId: [
        "d1707e23348143fb99537c9bddffcdb3",
        "3373f13e83b74f12a213a53db20a6657"
      ],
      newsId: ["d1707e23348143fb99537c9bddffcdb3"],
      policyId: ["3373f13e83b74f12a213a53db20a6657"],
      input: "",
      hotWord: "",
      activeName: "first",
      total: 0,
      pageNum: 1,
      pageSize: 3,
      newsList: [],
      dateVal: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  created() {
    this.input = this.$route.query.keyWord || '';
    this.getNewsKeyWords();
    this.getSearchNews();
  },
  methods: {
    search() {
      this.getSearchNews();
    },
    changeData() {
      if (this.dateVal != null) {
        let monthe = this.dateVal[0].getMonth() + 1;
        let mondatea = this.dateVal[0].getDate();
        if (monthe < 10) {
          monthe = "0" + monthe;
        }
        if (mondatea < 10) {
          mondatea = "0" + mondatea;
        }
        this.startDate =
          this.dateVal[0].getFullYear() + "-" + monthe + "-" + mondatea;
        let monthse = this.dateVal[1].getMonth() + 1;
        let mondatesa = this.dateVal[1].getDate();
        if (monthse < 10) {
          monthse = "0" + monthse;
        }
        if (mondatesa < 10) {
          mondatesa = "0" + mondatesa;
        }
        this.endDate =
          this.dateVal[1].getFullYear() + "-" + monthse + "-" + mondatesa;
      } else {
        this.startDate = "";
        this.endDate = "";
      }
      this.getSearchNews();
    },
    handleClick(tab, event) {
      console.log(tab.index);
      if (tab.index == 0) {
        this.columnIds = this.allId;
        this.getSearchNews();
      }
      if (tab.index == 1) {
        this.columnIds = this.newsId;
        this.getSearchNews();
      }
      if (tab.index == 2) {
        this.columnIds = this.policyId;
        this.getSearchNews();
      }
    },
    paginationChange(val) {
      this.pageNum = val;
      this.getSearchNews();
    },
    async getNewsKeyWords() {
      const api = this.$fetchApi.getNewsKeyWords.api;
      const data = await this.$fetchData.fetchPost({}, api, "json");
      if (data.code === "200") {
        if (data.result && data.result.rows) {
          let nowData = data.result.rows;
          for (let i in nowData) {
            if (i == 0) {
              this.hotWord = "热门搜索：" + nowData[i];
            } else {
              this.hotWord =
                this.hotWord +
                '<span style="margin: 0 20px;">|</span>' +
                nowData[i];
            }
          }
        }
      }
    },
    async getSearchNews() {
      this.newsList = [];
      const api = this.$fetchApi.pcNewsList.api;
      const data = await this.$fetchData.fetchPost(
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          columnIds: this.columnIds,
          keywords: this.input,
          startDate: this.startDate,
          endDate: this.endDate
        },
        api,
        "json"
      );
      if (data.code === "200") {
        if (data.result && data.result.rows) {
          let nowData = data.result.rows;
          this.total = data.result.total;
          for (let i in nowData) {
            this.newsList.push({
              columnId: nowData[i].columnId,
              informationId: nowData[i].informationId,
              title: nowData[i].title,
              detail: nowData[i].content,
              sourceName: nowData[i].sourceName,
              time: nowData[i].releaseDate
            });
          }
          console.log(data.result);
        }
      }
    },
    toDetail(columnId, informationId) {
      let idx = "";
      if (columnId == "d1707e23348143fb99537c9bddffcdb3") {
        idx = 0;
      }
      if (columnId == "3373f13e83b74f12a213a53db20a6657") {
        idx = 1;
      }
      this.$router.push({
        path: "/home/news/detail",
        query: {
          idx: idx,
          id: informationId,
          prev: "searchNews"
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.searchNewsBox {
  .breadcrumbOutside {
    border-bottom: 1px solid #d9d9d9;
    .breadcrumbBox {
      width: 1200px;
      padding: 19px 0;
      margin: 0 auto;
    }
  }
  .mainBox {
    width: 1200px;
    margin: 0 auto;
    .snb_top {
      height: 120px;
      .snbt_searchBox {
        width: 600px;
        height: 100%;
        margin: 0 auto;
        .search {
          width: 600px;
          height: auto;
          p {
            width: 100%;
            height: 20px;
            font-family: MicrosoftYaHei;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
            text-align: right;
            margin: 10px 0;
            a {
              &:hover {
                color: #448afc;
              }
            }
          }
          .search_input {
            display: flex;
            margin-top: 50px;
            .left_search {
              flex: 1;
            }
            .search_icon {
              margin-left: -2px;
              width: 60px;
              height: 40px;
              background-color: #448afc;
              z-index: 100;
              cursor: pointer;
              text-align: center;
              line-height: 35px;
              img {
                width: 14px;
                height: 14px;
                vertical-align: middle;
                transition: 0.5s;
              }
              &:hover img {
                transform: scale(1.15);
              }
            }
          }
        }
        .snbt_hotWordBox {
          line-height: 40px;
          font-size: 16px;
          color: #5d5d5d;
        }
      }
    }
    .snb_bottom {
      height: 600px;
      .snb_title {
        height: 50px;
        line-height: 50px;
        background-color: #fafafa;
        text-align: 50px;
        font-size: 14px;
        span {
          font-size: 20px;
          color: #2e60e2;
        }
        .snbmr_title {
          width: 96%;
          height: 50px;
          margin: 0 auto;
          position: relative;
          .snbmrt_font {
            width: 100%;
            margin: 0 auto;
            border-left: 6px solid #265ae1;
            font-size: 16px;
            line-height: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            text-indent: 0.5em;
            color: #2e60e2;
          }
        }
      }
      .snb_main {
        height: 480px;
        .snbm_newsList {
          height: 420px;
          .snbmn_block {
            height: 140px;
            cursor: pointer;
            .snbmn_row1 {
              height: 40px;
              line-height: 40px;
              color: #448afc;
              font-size: 18px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .snbmn_row2 {
              height: 40px;
              font-size: 14px;
              color: #5d5d5d;
              overflow: hidden;
              line-height: 20px;
            }
            .snbmn_row3 {
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              color: #448afc;
              span {
                color: #1aa441;
              }
            }
          }
        }
        .snbm_right {
          height: 480px;
          .snbmr_title {
            width: 96%;
            height: 50px;
            margin: 0 auto;
            position: relative;
            .snbmrt_font {
              width: 100%;
              margin: 0 auto;
              border-left: 6px solid #265ae1;
              font-size: 16px;
              line-height: 20px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              text-indent: 0.5em;
              color: #2e60e2;
            }
          }
        }
        .snbm_pageBox {
          height: 60px;
        }
      }
    }
  }
}
</style>